<template>
  <master-detail
    :canEdit="false"
    :cols="cols"
    :confirmDeleteMessage="confirmDeleteMessage"
    :customResource="customResource"
    :disableTableSaveButton="disableTableSaveButton"
    :filterQuery="filterQuery"
    formTitle="Alocar Horas"
    :lastRowData="horasTotaisPorDia"
    noReloadOnDelete
    :opts="opts"
    @onOpenFormDialog="onOpenFormDialog"
  >
    <div class="d-flex">
      <v-menu
        v-model="periodMenu"
        :close-on-click="false"
        :close-on-content-click="false"
        transition="scroll-y-transition"
        offset-y
        right
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="width: 370px"
            prefix="Período:"
            :value="period | toPeriod"
            dense
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            @click:prepend.stop="on.click"
            v-on="on"
            hide-details
            class="mb-n1 mt-0"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="period"
          range
          no-title
          @mouseenter:date="highlightWeek"
          @mouseleave:date="highlightWeekOver = []"
          :events="highlightWeekOver"
          :event-color="tableColor"
          @click:date="dateClick"
        ></v-date-picker>
      </v-menu>
      <v-autocomplete
        v-if="showColaboradorSelect"
        v-model="colaboradorId"
        :items="opts.colaboradores"
        class="mb-n1 mt-0 ml-3"
        style="width: 370px"
        item-value="id"
        item-text="nome"
        prefix="Colaborador"
        prepend-icon="mdi-account"
        placeholder="Todos"
        dense
        clearable
        hide-details
      ></v-autocomplete>
    </div>
  </master-detail>
</template>

<script>
import { cloneDeep, filter, findIndex, forEach, groupBy, map, remove, uniq } from "lodash";
import moment from "moment";
import { mapGetters } from 'vuex';
import { UserTypeEnum } from '@/core/enums/user-types';
import compareFloatNum from "@/helpers/compareFloatNum";

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'user']),
    cols: function () {
      const week = this.weekFromDate(this.period[0]);      
      const days = week.map((day) => ({
        key: day,
        name: this.$options.filters.toWeekDay(day),
        type: this.$fieldTypes.TIME,
        editOnTable: true,
        editable: this.dateInsideProjectPeriod(day),
        hideInform: this.dateInsideProjectPeriod(day, true),
        align: 0,
        width: 98,
        colSize: 3,
        commentForField: `comment${day}`,
      }));

      return [
        {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: this.opcoesProjetosParsed, key: "id", name: "titulo" },
          hideInTable: true,
          rules: [{ rule: "required" }],
        },
        {
          key: "titulo",
          name: "Projeto",
          hideInform: true,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "atividadeId",
          name: "Atividade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "atividades", key: "id", name: "atividade" },
          rules: [{ rule: "required" }],
          hideInTable: true,
        },
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "funcionarioId",
          name: "Colaborador",
          hideInform: !!this.funcionarioId,
          hideInTable: true,
          defaultValue: !this.funcionarioId ? [] : [this.funcionarioId],
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "colaboradores", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "nome",
          name: "Colaborador",
          hideInform: true,
          hideInTable: !!this.funcionarioId,
          type: this.$fieldTypes.TEXT,
        },
        ...days,
        {
          key: "total",
          name: "Total",
          type: this.$fieldTypes.TIME,
          editable: false,
          hideInform: true,
        }
      ];
    },
    customResource: function () {
      const resource = this.apiResource(`/v1/timesheet/${this.clientId}${this.funcionarioId ? `/${this.funcionarioId}` : ''}`)
      return {
        ...resource,
        get: (...args) => {
          return resource
            .get(...args)
            .then((result) => {
              const timesheets = result.timesheets || result;
              const safeTimesheets = Array.isArray(timesheets) ? timesheets : [];
              this.timeSheetsOfWeek = safeTimesheets;
              this.parsedTimeSheetsOfWeek = this.parseTimeSheet(safeTimesheets);
              return this.parsedTimeSheetsOfWeek;
            });
        },
        save: (obj, id) => {
          return new Promise((resolve, reject) => {
            const periodo = this.weekFromDate(this.period[0]);
            var operations = { save: [], delete: [] };
            forEach(obj, (value, key) => {
              forEach(obj.funcionarioId, (funcionarioId) => {
                if (moment(key, "YYYY-MM-DD", true).isValid()) {
                  const exist = find(
                    id || this.timeSheetsOfWeek,
                    (o) =>
                      o.data == key &&
                      o.projetoId == obj.projetoId &&
                      o.atividadeId == obj.atividadeId &&
                      o.funcionarioId == funcionarioId
                  );
                  const newValue = parseFloat(`${value}`).toFixed(4) * 1;
                  const oldValue = parseFloat(`${exist?.tempo || 0}`).toFixed(4) * 1;
                  const oldComment = exist ? (exist[`comment${key}`] || exist.comentario) : null;
                  const newComment = obj[`comment${key}`];
                  const hasChanges = !compareFloatNum(newValue, oldValue) || newComment != oldComment;

                  if (hasChanges && newValue > 0) {
                    operations.save.push({
                      id: exist ? exist.id : undefined,
                      data: key,
                      tempo: newValue,
                      projetoId: obj.projetoId,
                      atividadeId: obj.atividadeId,
                      funcionarioId: funcionarioId,
                      comentario: obj[`comment${key}`],
                    });
                  } else if (hasChanges && exist) {
                    operations.delete.push(exist.id);
                  }
                }
              });
            });

            const actions = [];
            forEach(operations.save, (objToSave) => {
              actions.push(resource.save(objToSave, objToSave.id));
            });
            forEach(operations.delete, (idToDelete) => {
              actions.push(resource.delete(idToDelete));
            });

            Promise.all(actions)
              .then((values) => {
                values.forEach(val => {
                  if ('deletado' in val) {
                    remove(this.timeSheetsOfWeek, ({ id }) => val.deletado == id);
                    remove(id, ({ id }) => val.deletado == id);
                  } else {
                    const index = findIndex(this.timeSheetsOfWeek, ({ id }) => val.id == id);
                    if (index === -1) {
                      this.timeSheetsOfWeek.push(val);

                      if (id) {
                        id.push(val);
                      }
                    } else {
                      this.timeSheetsOfWeek.splice(index, 1, val);
                      id.splice(index, 1, val);
                    }
                  }
                })

                this.timeSheetsOfWeek = [...this.timeSheetsOfWeek];

                obj.total = 0;
                periodo.forEach((key) => {
                  obj.total += obj[key] || 0;
                });

                resolve(values);
              })
              .catch(reject);
          });
        },
        delete: (obj) => {
          const timesheets = Array.isArray(obj) ? obj : [];
          const requests = timesheets
            .map(({ id }) => id)
            .filter((id) => !!id)
            .map(id => resource.delete(id));
          return Promise.all(requests).then((values) => {
            const periodo = this.weekFromDate(this.period[0]);
            const idsDeletados = values.map(({ deletado }) => typeof deletado === 'string' ? parseInt(deletado, 10) : deletado).sort();
            const linha = this.parsedTimeSheetsOfWeek.timesheets.find(({ id: originalTimesheets }) => {
              const timesheetIds = (originalTimesheets || []).map(({ id }) => id);
              const result = timesheetIds.some((id) => idsDeletados.includes(id));
              return result;
            });

            if (linha) {
              periodo.forEach((key) => {
                delete linha[key];
                delete linha[`comment${key}`];
                delete linha.id;
              })
              delete linha.id;
              linha.total = 0;
            }
            remove(this.timeSheetsOfWeek, ({ id }) => idsDeletados.includes(id));

            return values;
          });
        }
      };
    },
    filterQuery: function () {
      const [dataIni, dataFim] = this.period;
      return `dataIni=${dataIni}&dataFim=${dataFim}${this.colaboradorId ? `&funcionarioId=${this.colaboradorId}` : ''}`;
    },
    funcionarioId: function () {
      return this.user.funcionarioId;
    },
    horasTotaisPorDia: function () {
      const colaboradoresNaTabela = this.timeSheetsOfWeek.map(({ funcionarioId }) => funcionarioId);
      const uniqColaboradores = uniq(colaboradoresNaTabela);
      
      if (uniqColaboradores.length === 1) {
        return this.timeSheetsOfWeek.reduce((acc, { data, tempo }) => {
          const temp = { ...acc };
          const parsedTempo = tempo * 1;
          if (data in temp) {
            temp[data] += parsedTempo;
          } else {
            temp[data] = parsedTempo;
          }
          return temp;
        }, {});
      }

      return null;
    },
    opcoesProjetosParsed: function () {
      const week = this.weekFromDate(this.period[0]);
      const periodoInicial = week[0];
      const periodoFinal = week[6];
      return this.opts.projetos
        .map(({ data_fim, data_inicio, id, titulo, ...rest }) => {
          const disabled = data_inicio && data_fim && !((data_inicio <= periodoInicial && periodoInicial <= data_fim) && (data_inicio <= periodoFinal && periodoFinal <= data_fim));
          const safeTitle = (titulo || 'Titulo não encontrado') + (disabled ? ' (Projeto já finalizado)' : '');
          return { data_fim, data_inicio, disabled, id, titulo: safeTitle, ...rest };
        })
        .sort((a, b) => a.titulo.localeCompare(b.titulo));
    },
    resourceAtividades: function () {
      return this.apiResource(`/v1/timesheet/${this.clientId}/atividades`);
    },
    resourceColaboradores: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/selecao`);
    },
    showColaboradorSelect: function () {
      return this.user.tipo_usuario != UserTypeEnum.COLABORADOR;
    },
  },
  created: function () {
    let dataIni = this.$route.query.dataIni;
    let dataFim = this.$route.query.dataFim;

    if (dataIni && dataFim) {
      this.period = [dataIni, dataFim];
    } else {
      this.period = this.getFilters('period') || this.getWeekPeriod(moment());
    }

    this.resourceColaboradores.get().then((response) => {
      this.opts.colaboradores = response.colaboradores
        .sort((a, b) => a.nome.localeCompare(b.nome))
        .map((col) => ({ ...col, nome: `${col.matricula} — ${col.nome}`}));
    });

    this.resourceAtividades.get().then((response) => {
      this.opts.atividades = response;
    });
  },
  data: function () {
    return {
      colaboradorId: null,
      period: [],
      periodMenu: false,
      timeSheetsOfWeek: [],
      parsedTimeSheetsOfWeek: {},
      highlightWeekOver: [],
      opts: {
        atividades: [],
        colaboradores: [],
        projetos: [],
        projetosCadastro: [],
      },
    };
  },
  methods: {
    confirmDeleteMessage: function ({ atividade, nome, titulo }) {
      return `Deseja excluir todos os registros da semana para o projeto ${titulo || 'Título não encontrado'}, atividade ${atividade || 'não informada'}${nome ? `, colaborador ${nome}` : ''}?`;
    },
    dateClick: function (day) {
      this.period = this.getWeekPeriod(moment(day, "YYYY-MM-DD"));
      this.periodMenu = this.period.length != 2;
    },
    dateInsideProjectPeriod: function (date, inverse = false) {
      return (linha) => {
        if (!linha || !Array.isArray(this.opts.projetos)) {
          return true;
        }

        const { projetoId } = linha;
        const projeto = this.opts.projetos.find(({ id }) => id === projetoId);

        if (!projeto) {
          return true;
        }

        const { data_inicio, data_fim } = projeto;
        const res = !data_inicio || !data_fim || data_inicio <= date && date <= data_fim;

        return inverse ? !res : res;
      };
    },
    disableTableSaveButton: function (linha) {
      const funcionarioId = Array.isArray(linha.funcionarioId) && linha.funcionarioId.length > 0 ? linha.funcionarioId[0] : null;
      const exist = filter(this.timeSheetsOfWeek, (o) =>
        o.data in linha &&
        o.projetoId == linha.projetoId &&
        o.atividadeId == linha.atividadeId &&
        o.funcionarioId == funcionarioId
      );
      const result = !this.weekFromDate(this.period[0]).some((day) => {
        const timesheet = exist.find(({ data }) => data === day);
        const newTempo = parseFloat(`${linha[day]}`).toFixed(4) * 1;
        const oldTempo = parseFloat(`${timesheet?.tempo || 0}`).toFixed(4) * 1;
        const newComment = linha[`comment${day}`];
        const oldComment = timesheet && timesheet.comentario;
        return timesheet ? (!compareFloatNum(newTempo, oldTempo) || newComment != oldComment) : newTempo > 0;
      });

      return result;
    },
    getWeekPeriod: function (date) {
      let anoBase = date.get('year');
      let today = moment(date);
      let start = today.day(0);
      let end = moment(start).day(6);

      if (start.get('year') !== anoBase) {
        start = moment(`${anoBase}-01-01`, "YYYY-MM-DD");
      }
      
      if (end.get('year') !== anoBase) {
        end = moment(`${anoBase}-12-31`, "YYYY-MM-DD");
      }

      return [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")];
    },
    getProjetosByYear: async function (year) {
      try {
        const resource = this.apiResource(`/v1/projetos/${this.clientId}/selecao?ano=${year}`);
        const response = await resource.get();
        
        if (response.error) {
          throw response;
        }

        const projetos = Array.isArray(response.projetos) ? response.projetos : [];
        this.opts.projetos = projetos;
      } catch (error) {
        this.opts.projetos = [];
        throw error;
      }
    },
    highlightWeek: function (date) {
      this.highlightWeekOver = this.weekFromDate(date);
    },
    onOpenFormDialog: function () {
      const [dataIni] = this.period;
      const ano = moment(dataIni).format('YYYY');
      this.getProjetosByYear(ano);
    },
    parseTimeSheet: function (timesheets) {
      if (!Array.isArray(timesheets)) {
        return [];
      }

      const groups = groupBy(timesheets, ({ atividadeId, atividade, funcionarioId, nome, projetoId, titulo }) => [atividadeId, atividade, funcionarioId, nome, projetoId, titulo]);
      return map(groups, (values, keys) => {
        const [atividadeId, atividade, funcionarioId, nome, projetoId, titulo] = keys.split(",");
        const id = cloneDeep(values);
        const safeFuncionarioId = funcionarioId ? [funcionarioId] : [];
        let ts = { atividadeId, atividade, funcionarioId: safeFuncionarioId, id, nome, projetoId, titulo, total: 0 };

        forEach(values, ({ data, tempo, comentario }) => {
          const parsedTempo = Number(tempo) || 0;
          const parsedData = ts[data] ? Number(ts[data]) : 0;
          ts[data] = parsedData + parsedTempo;
          ts[`comment${data}`] = comentario;
          ts.total += parsedTempo;
        });

        return ts;
      });
    },
    weekFromDate: function (date) {
      let today = moment(date);
      let [start, end] = this.getWeekPeriod(today);
      let size = moment(end).diff(moment(start), 'days');
      return new Array(size + 1).fill(start).map((_, index) => moment(start).add(index, 'days').format("YYYY-MM-DD"));
    },
  },
  watch: {
    period: function (value) {
      this.setFilters({ period: value });
    }
  }
};
</script>